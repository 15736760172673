import React from "react"
import Layout from "../components/layouts/Layout"
import { SectionPageWrapper } from "../components/layouts"
import SEO from "../components/seo"

const Booking = () => {
  return (
    <Layout>
      <SEO title="EDU" />
      <SectionPageWrapper>
        <h1 css={`text-align: center`}>Book Your Free Consultation</h1>
        <iframe
          src="https://tom-caraher.bookafy.com/schedule?type=iframe&locale=en"
          height="800"
          width="100%"
          frameBorder="0"
        >
          {" "}
        </iframe>
      </SectionPageWrapper>
    </Layout>
  )
}

export default Booking
